<template>
  <el-dialog
    custom-class="w-block-dialog"
    v-bind="attributes"
    :visible.sync="visible"
    destroy-on-close
    :close-on-click-modal="false"
    :before-close="beClose"
    @close="dialogClose"
    class="setDialog"
  >
    <div class="dialog-container">
      <div class="lt">
        <span class="title">可选列表</span>
        <div class="tab">
          <div class="tab-header">
            <div
              :class="[tabId == item.id ? 'tab-active' : '', 'tab-item']"
              v-for="item in tabList"
              :key="item.id"
              @click="clickTab(item.id)"
            >{{ item.name }}</div>
          </div>
          <div class="tab-container" v-show="tabId == 0">
            <el-tree
              class="filter-tree w-is-scroll"
              :check-strictly="tabKey === 3"
              :data="userList ? userList : []"
              :props="defaultuserProps"
              show-checkbox
              node-key="id"
              @check="currentChecked"
              @check-change="handleCheckChange"
              ref="memberList"
              default-expand-all
            ></el-tree>
          </div>
          <div class="tab-container" v-show="tabId == 1">
            <el-tree
              class="filter-tree w-is-scroll"
              :data="departmentData ? departmentData : []"
              :props="defaultProps"
              show-checkbox
              node-key="id"
              @check="currentChecked"
              ref="departmentList"
              default-expand-all
            ></el-tree>
          </div>
          <!-- 指定角色 -->
          <div class="tab-container" v-show="tabId == 2">
            <el-tree
              class="filter-tree w-is-scroll"
              :data="roleList ? roleList : []"
              :props="defaultRoleProps"
              show-checkbox
              node-key="id"
              @check="currentChecked"
              ref="roleList"
              default-expand-all
            ></el-tree>
          </div>
          <!-- 选取工作组 -->
          <div class="tab-container" v-show="tabId == 3">
            <el-tree
              class="filter-tree w-is-scroll"
              :data="teamList ? teamList : []"
              :props="defaultTeamProps"
              show-checkbox
              node-key="id"
              @check="currentChecked"
              ref="teamList"
              default-expand-all
            ></el-tree>
          </div>
          <!-- 选择工作组内的成员 -->
          <div class="tab-container" v-show="tabId == 4">
            <el-tree
              class="filter-tree w-is-scroll"
              :data="teamMemberList ? teamMemberList : []"
              :props="defaultTeamMemberProps"
              show-checkbox
              node-key="id"
              @check="currentChecked"
              @check-change="clickTreeDeal"
              ref="teamMemberList"
              default-expand-all
            ></el-tree>
          </div>

          <!-- 选择工作组成员--单选 -->
          <div class="tab-container" v-show="tabId == 5">
            <el-tree
              class="filter-tree w-is-scroll"
              :check-strictly="true"
              :data="teamMemberList ? teamMemberList : []"
              :props="defaultTeamMemberProps"
              show-checkbox
              node-key="id"
              @check="currentChecked"
              @check-change="handleCheckChange"
              ref="singleTeamMemberList"
              default-expand-all
            ></el-tree>
          </div>
        </div>
      </div>
      <div class="rt">
        <span class="title">已选列表</span>
        <div class="chosed-list">
          <div class="chosed-item" v-for="(item,index) in confirmList2" :key="'chosed' + index">
            <div style="display: flex;align-items: center;">
              <img :src="item.ico" style="width:16px;height:16px" />
              <span style="margin-left: 6px;">{{ item.name }}</span>
            </div>
            <i class="icon-work-close" @click="delChosedItem(item)"></i>
          </div>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <span @click="comfirmDialog">{{ btnTxt[0] }}</span>
      <span @click="close">{{ btnTxt[1] }}</span>
    </span>
  </el-dialog>
</template>
<script>
import api from '@/api'
export default {
  name: 'setDialog',
  props: {
    config: Object,
    selfData: {
      type: Array
    },
    beforeClose: {
      type: Function,
      default: () => { }
    },
    tabKey: { // =3 开启部门人员单选
      type: Number
    }
  },
  data () {
    const { title = '人员设置', width = '760px', btnTxt = ['确 定', '取 消'] } = this.config || {}
    return {
      edObject: {
        memberList: [], // 部门成员列表
        departmentList: [], // 部门列表
        roleList: [], // 角色列表
        teamList: [], // 工作组
        teamMemberList: [] // 工作组成员列表
      },
      visible: false,
      attributes: {
        title,
        width
      },
      btnTxt,
      departmentData: [],
      chosedList: [],
      confirmList: [],
      teamMemberList: [],
      userList: [],
      roleList: [],
      teamList: [],
      tabId: 0,
      tabList: [],
      treeData: [],
      defaultProps: {
        children: 'childList',
        label: 'name'
      },
      defaultuserProps: {
        children: 'childList',
        label: 'name'
      },
      defaultRoleProps: {
        childList: '',
        label: 'name'
      },
      defaultTeamProps: { // 指定工作组 指向
        children: '',
        label: 'name'
      },
      defaultTeamMemberProps: {
        children: 'childList',
        label: 'name'
      }
    }
  },
  computed: {
    confirmList2 () {
      const arr = []
      for (const item in this.edObject) {
        if (this.edObject[item].length > 0) { arr.push(...this.edObject[item]) }
      }
      return arr
    }
  },
  watch: {
    tabKey (newValue) {
      if (this.tabList.some(item => item.id === 4 || item.id === 5)) {
        this.getTeamList().then(e => {
          this.init(this.selfData)
        })
      }
      this.disableTree(newValue)
    },
    selfData (val) {
      console.log('val->', val)
      this.init(val)
    },
    visible (val) {
      if (val === false) {
        Object.assign(this.$data.edObject, this.$options.data().edObject)
      }
    }
  },
  methods: {
    init (val) {
      // const that = this
      console.log('模块初始化', val)
      // 只接收处理好的数据格式 设置显示
      // 0：成员；1：部门；2：角色；3：工作组   - type

      val.forEach(item => {
        switch (item.memberType) {
          case 0:
            if (this.tabList.some(tabItem => tabItem.id === 0)) { // 部门成员
              this.edObject.memberList.push(item)
              this.$nextTick(() => {
                this.$refs.memberList.setChecked(item.member, true, false)
              })
            }
            if (this.tabList.some(tabItem => tabItem.id === 4)) { // 工作组成员
              // 手动使 id = member
              item.id = item.member
              this.edObject.teamMemberList.push(item)
              this.teamMemberList.forEach(memberItem => {
                if (memberItem.childList && memberItem.childList.length > 0) {
                  memberItem.childList.forEach(childItem => {
                    if (item.name === childItem.name) {
                      this.$nextTick(() => {
                        this.$refs.teamMemberList.setChecked(childItem.id, true, false)
                      })
                    }
                  })
                }
              })
            }
            break
          case 1:
            this.edObject.departmentList.push(item)
            this.$nextTick(() => {
              this.$refs.departmentList.setChecked(item.member, true, false)
            })
            break
          case 2:
            this.edObject.roleList.push(item)
            this.$nextTick(() => {
              this.$refs.roleList.setChecked(item.member, true, false)
            })
            break
          case 3:
            this.edObject.teamList.push(item)
            this.$nextTick(() => {
              this.$refs.teamList.setChecked(item.member, true, false)
            })
            break
        }
      })
    },
    open (ok) { // 弹窗打开方法
      this.ok = ok
      this.visible = true
      return this.$nextTick()
    },
    dialogClose () {
      this.$emit('closeDialog')
    },
    beClose (done) {
      console.log('关闭前回调')

      done()
      this.beforeClose()
      this.close()
    },
    clickTreeDeal (curObj, objStatus) {
      // console.log('121312', curObj, objStatus)
      const chosedList = this.$refs.teamMemberList.getCheckedKeys()
      if (objStatus) {
        // 新增 把所有已经选中的带进来
        const teamMemberListArr = [...chosedList, curObj.id]
        this.teamMemberList.forEach(teamItem => {
          if (teamItem.childList && teamItem.childList.length > 0) {
            teamItem.childList.forEach(childItem => {
              if (curObj.name === childItem.name) {
                teamMemberListArr.push(childItem.id)
              }
            })
          }
        })
        this.$refs.teamMemberList.setCheckedKeys(teamMemberListArr)
      } else {
        this.teamMemberList.forEach(teamItem => {
          if (teamItem.childList && teamItem.childList.length > 0) {
            teamItem.childList.forEach(childItem => {
              if (curObj.name === childItem.name) {
                this.$refs.teamMemberList.setChecked(childItem.id, false, false)
              }
            })
          }
        })
      }
    },
    // 获取指定工作组 以及工作组成员的函数
    async getTeamList () {
      const params = {
        configId: this.config ? this.config.configId : 0,
        nodeId: 0,
        type: 0,
        subConfigId: this.config ? this.config.subConfigId ? this.config.subConfigId : 0 : 0
      }
      await this.axios.get(api.getHandlerList, { params }).then(res => {
        this.teamMemberList = res.data.data.userList.map(item => {
          if (item.childList && item.childList.length > 0) {
            item.childList = item.childList.map(item2 => {
              item2.id = parseInt(`${item.id}` + `${item2.id}`)
              return item2
            })
          }
          return item
        })
        if (this.tabKey === 4) {
          this.disableTree(this.tabKey)
        }
      })
    },
    // 获取Tab成员列表
    async getMemberList () {
      const params = {
        configId: this.config ? this.config.configId : 0,
        nodeId: 0
      }
      await this.axios.get(api.getSponsorList, { params }).then(res => {
        this.teamList = res.data.data.teamList
        this.userList = res.data.data.userList
        this.roleList = res.data.data.roleList
        this.departmentData = res.data.data.deploymentList
        this.disableTree(this.tabKey)
        console.log('=+++++')
      })
    },
    close () {
      this.visible = false
    },
    comfirmDialog () {
      console.log('输出结果-->', this.confirmList2)
      const list = JSON.parse(JSON.stringify(this.confirmList2))
      this.$emit('comfirm', list)
      this.visible = false
    },

    // 删除节点
    delChosedItem (item) {
      console.log(item)
      const key = item.memberType
      const delId = item.id
      console.log('delId', delId)
      // const arr = ['memberList', 'departmentList', 'roleList', 'teamList', 'teamMemberList', 'singleTeamMemberList']
      switch (key) {
        case 0:
          if (this.tabList.some(item => item.id === 0)) { // 部门成员
            this.edObject.memberList = this.edObject.memberList.filter(item => {
              return item.id !== delId
            })
            this.$refs.memberList.setCheckedNodes(this.edObject.memberList)
          }
          if (this.tabList.some(item => item.id === 4)) { // 工作组成员
            console.log('dadad++')
            this.edObject.teamMemberList = this.edObject.teamMemberList.filter(item => {
              return item.id !== delId
            })
            this.teamMemberList.forEach(xxItem => {
              if (xxItem.childList && xxItem.childList.length > 0) {
                xxItem.childList.forEach(xxxItem => {
                  if (xxxItem.name === item.name) {
                    this.$refs.teamMemberList.setChecked(xxxItem.id, false, false)
                  }
                })
              }
            })
          }
          if (this.tabList.some(item => item.id === 5)) {
            this.edObject.teamMemberList = this.edObject.teamMemberList.filter(item => {
              return item.id !== delId
            })
            this.$refs.singleTeamMemberList.setCheckedNodes(this.edObject.teamMemberList)
          }
          break
        case 1:
          this.edObject.departmentList = this.edObject.departmentList.filter(item => {
            return item.id !== delId
          })
          this.$refs.departmentList.setCheckedNodes(this.edObject.departmentList)
          break
        case 2:
          this.edObject.roleList = this.edObject.roleList.filter(item => {
            return item.id !== delId
          })
          this.$refs.roleList.setCheckedNodes(this.edObject.roleList)
          break
        case 3:
          this.edObject.teamList = this.edObject.teamList.filter(item => {
            return item.id !== delId
          })
          this.$refs.teamList.setCheckedNodes(this.edObject.teamList)
          break
      }
    },

    handleCheckChange (data, checked) { // 单选逻辑处理
      if (this.tabKey === 3) {
        if (checked) {
          this.$refs.memberList.setCheckedKeys([data.id])
        }
      } else if (this.tabKey === 4) {
        if (checked) {
          this.$refs.singleTeamMemberList.setCheckedKeys([data.id])
        }
      }
    },
    async delRepeat (e) { // 去重
      const obj = {}
      let list = []
      return await new Promise((resolve, reject) => {
        /* eslint-disable */
        list = e.reduce((cur, next) => {
          obj[next.name] ? '' : obj[next.name] = true && cur.push(next)
          return cur
        }, [])
        /* eslint-disable */
        resolve(list);
      })
    },
    currentChecked (nodeObj, SelectedObj) {
      console.log('nodeObj', nodeObj)
      console.log('key1', SelectedObj.checkedKeys) // 这是选中的节点的key数组
      console.log(SelectedObj.checkedNodes) // 这是选中的节点数组

      const arr = SelectedObj.checkedNodes
      const obj = {}
      let key = this.tabId

      switch (key) {
        case 0: // 指定部门成员   单选 多选
          if (this.tabKey === 3) { // 单选
            this.edObject.memberList = arr
          } else { //多选
            this.edObject.memberList = arr.filter(item => {
              return item.memberType !== 1
            })
          }
          break
        case 1: // 指定部门
          // 筛选末级节点
          this.edObject.departmentList = arr.filter(arrItem => {
            return arrItem.childList.length === 0
          })
          break
        case 2: //指定角色
          this.edObject.roleList = arr
          break
        case 3: //指定工作组
          this.edObject.teamList = arr
          break
        case 4: //指定工作组成员 单选 多选
          this.edObject.teamMemberList = arr.filter(item => {
            return item.memberType !== 3
          }).reduce((cur, next) => {
            obj[next.name] ? '' : obj[next.name] = true && cur.push(next)
            return cur
          }, [])
          break
        case 5:
          this.edObject.teamMemberList = arr
          break
      }
    },
    clickTab (key) {
      this.tabId = key
    },
    getTab () {
      const params = {}
      this.axios.post(api.departmentList, params).then(res => {
        this.treeData = res.data.data.managementVOS
      })
    },
    handleTabs () {
      if (this.tabKey === 0) {

        this.tabList = [{ id: 4, name: '指定成员' }, { id: 3, name: '指定工作组' }]

      } else if (this.tabKey === 1) {

        this.tabList = [{ id: 0, name: '指定成员' }, { id: 1, name: '指定部门' }, { id: 2, name: '指定角色' }]

      } else if (this.tabKey === 2) {

        this.tabList = [{ id: 0, name: '指定人' }, { id: 1, name: '指定部门' }, { id: 2, name: '指定角色' }, { id: 3, name: '指定工作组' }]

      } else if (this.tabKey === 3) {

        this.tabList = [{ id: 0, name: '指定成员' }]

      } else if (this.tabKey === 4) {

        this.tabList = [{ id: 5, name: '指定成员' }]

      } else {

        this.tabList = [{ id: 4, name: '指定成员' }, { id: 3, name: '指定工作组' }]

      }
      this.tabId = this.tabList[0].id
    },
    disableTree (newValue) {
      if (newValue === 3) {
        this.userList = this.userList.map(item => {
          if (item.memberType) {
            item.disabled = true
            if (item.childList && item.childList.length > 0) {
              for (let i = 0; i < item.childList.length; i++) {
                const item0 = item.childList[i]
                if (item0.memberType) {
                  item0.disabled = true
                }
                if (item0.childList && item0.childList.length > 0) {
                  for (let i = 0; i < item0.childList.length; i++) {
                    const item1 = item0.childList[i]
                    if (item1.memberType) {
                      item1.disabled = true
                    }
                    if (item1.childList && item1.childList.length > 0) {
                      for (let i = 0; i < item1.childList.length; i++) {
                        const item2 = item1.childList[i]
                        if (item2.memberType) {
                          item2.disabled = true
                        }
                        if (item2.childList && item2.childList.length > 0) {
                          for (let i = 0; i < item2.childList.length; i++) {
                            const item3 = item2.childList[i]
                            if (item3.memberType) {
                              item3.disabled = true
                            }
                            if (item3.childList && item3.childList.length > 0) {
                              for (let i = 0; i < item3.childList.length; i++) {
                                const item4 = item3.childList[i]
                                if (item4.memberType) {
                                  item4.disabled = true
                                }
                                if (item4.childList && item4.childList.length > 0) {
                                  for (let i = 0; i < item4.childList.length; i++) {
                                    const item5 = item4.childList[i]
                                    if (item5.memberType) {
                                      item5.disabled = true
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          return item
        })
      }
      if (newValue === 4) {
        this.teamMemberList = this.teamMemberList.map(item => {
          if (item.memberType === 3) {
            item.disabled = true
          }
          return item
        })

      }
    }
  },
  mounted () {
    this.getMemberList().then(ee => {
      if (this.tabList.some(item => item.id === 4 || item.id === 5)) {
        this.getTeamList().then(e => {
          this.init(this.selfData)
        })
      } else {
        this.init(this.selfData)
      }
    })
    this.handleTabs()
    this.getTab()

  }
}
</script>
<style lang="stylus" scoped>
.setDialog>>>
  .el-tree
    background #373A43
    min-height 320px
  .el-tree-node:focus
    .el-tree-node__content
      background none
  .el-tree-node__label
    color #B2B3BD
  .el-checkbox__inner
    background none
    border 1px solid #808191
  .el-tree-node__content:hover
    background none
  .w-block-dialog
    .dialog-footer
      display flex
      justify-content center
      span
        color #fff
        font-size 14px
        border-radius 4px
        width 88px
        height 36px
        line-height 36px
        text-align center
        opacity 1
        cursor pointer
        &:nth-child(1)
          background #F89407
        &:nth-child(2)
          background #373A43
          margin-left 20px
    .dialog-container
      display flex
      justify-content space-between
      font-size 14px
      .lt
        width 320px
        .title
          color #B2B3BD
        .tab
          margin-top 10px
          display flex
          flex-direction column
          .tab-header
            display flex
            .tab-item
              cursor pointer
              flex 1
              height 36px
              line-height 36px
              text-align center
              background #2e3138
              color #B2B3BD
              font-size 14px
              border-radius 2px 2px 0px 0px
              &:nth-child(n+2)
                margin-left 1px
            .tab-active
              color #fff
              background #373A43
          .tab-container
            overflow scroll
            overflow-x hidden
            max-height 320px
            &::-webkit-scrollbar
              width 2px
              height 16px
              background-color #373a43
            &::-webkit-scrollbar-track
              -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
              border-radius 10px
              background-color #373a43
            &::-webkit-scrollbar-thumb
              border-radius 10px
              -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
              background-color #373a43
      .rt
        width 320px
        .chosed-list
          margin-top 10px
          background #373A43
          min-height 356px
          padding 10px
          overflow scroll
          overflow-x hidden
          max-height 320px
          &::-webkit-scrollbar
            width 2px
            height 16px
            background-color #373a43
          &::-webkit-scrollbar-track
            -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
            border-radius 10px
            background-color #373a43
          &::-webkit-scrollbar-thumb
            border-radius 10px
            -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
            background-color #373a43
          .chosed-item
            padding 4px 0
            display flex
            justify-content space-between
            align-items center
            color #B2B3BD
            i
              &:nth-child(2)
                cursor pointer
</style>
